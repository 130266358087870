<template>
  <div id="alarm">
    <div class="content">
      <h3 class="title">总量查询</h3>
      <div class="search-criteria">
        <a-space :size="size">
          <div class="form-some">
            <div class="large-title">企业名称</div>
            <a-input
              v-model="EnterpriseMC"
              style="width: 130px"
              placeholder="请输入企业名称"
            />
          </div>
          <div class="form-some">
            <div class="large-title">基站名称</div>
            <a-input
              v-model="station_name"
              style="width: 130px"
              placeholder="请输入基站名称"
            />
          </div>
          <div class="form-some">
            <div class="large-title">基站类型</div>
            <base-select
              v-bind:width="width"
              @baseChange="handleChange"
            ></base-select>
          </div>
          <div class="form-some">
            <div class="large-title">时间段:</div>
            <a-range-picker
              style="width: 240px"
              :default-value="[
                moment($store.state.thisMonth),
                moment($store.state.JSSJ),
              ]"
              show-time
              :size="size"
              :format="dateFormat"
              @change="onChangeDate"
            />
          </div>
          <a-button
            style="margin-left: 20px"
            :size="size"
            type="primary"
            @click="onSubmit"
          >
            确定
          </a-button>
          <a-button
            style="margin-left: 10px"
            :size="size"
            type="primary"
            @click="onExport"
          >
            导出
          </a-button>
        </a-space>
      </div>

      <div class="table">
        <a-table
          :scroll="scroll"
          size="small"
          :loading="loading"
          :row-key="(record) => record.ROW"
          bordered
          :pagination="false"
          :columns="columns"
          :data-source="data"
        >
          <template slot="useTotal" slot-scope="useTotal">
            <div class="utilization-text">
              <div class="utilization-shell">
                <div
                  v-if="useTotal >= 0 && useTotal <= 100"
                  class="utilization"
                  :style="'width:' + useTotal + '%;'"
                ></div>
                <div v-else class="utilization" :style="'width:100%;'"></div>
              </div>
              <span>{{ useTotal }}</span>
            </div>
          </template>
        </a-table>
        <div style="display: flex; padding: 10px">
          <div style="flex: 1"></div>
          <a-pagination
            show-quick-jumper
            :page-size="pageSize"
            v-model="current"
            :total="total"
            :show-total="
              (total, range) =>
                `显示 ${range[0]}-${range[1]} 条，共 ${total} 条`
            "
            @change="onChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "../../utils/Http.js";
import moment from "moment";
import baseSelect from "../../components/BaseSelect.vue";

const columns = [
  {
    title: "序号",
    align: "center",
    width: 60,
    customRender: (text, record, index) => {
      return index + 1;
    },
  },
  {
    title: "企业名称",
    dataIndex: "EnterpriseMC",
    align: "center",
    ellipsis: true,
  },
  {
    title: "基站名称",
    dataIndex: "station_name",
    align: "center",
  },
  {
    title: "基站类型",
    dataIndex: "station_TypeName",
    align: "center",
    render: (text, record, index) => {
      return "基站类型";
    },
  },
  {
    title: "综合排放量(kg)",
    dataIndex: "dischargeTotal",
    align: "center",
  },
  {
    title: "使用率(%)",
    align: "center",
    width: 220,
    dataIndex: "useTotal",
    scopedSlots: {
      customRender: "useTotal",
    },
  },
  {
    title: "税额合计(元)",
    dataIndex: "TaxAmountTotal",
    align: "center",
  },
];
export default {
  data() {
    return {
      size: "default",
      dateFormat: "YYYY-MM-DD",
      width: "120px",
      EnterpriseMC: "",
      station_name: "",
      station_Type: 0,
      KSSJ: this.$store.state.thisMonth,
      JSSJ: this.$store.state.JSSJ,
      current: 1,
      pageSize: 20,
      total: 0,
      columns,
      data: [],
      loading: false,
      scroll: { y: 530 }
    };
  },

  components: {
    baseSelect,
  },

  created() {
    var me = this;
    this.HJJC_Enterprise_Aggregate_Query_Ext();
  },

  mounted() {},

  methods: {
    moment,

    //选择日期
    onChangeDate(date, dateString) {
      console.log(dateString);
      this.KSSJ = dateString[0];
      this.JSSJ = dateString[1];
    },

    //导出
    onExport() {
      let me = this;

      let ID = `${me.$store.state.loggedIn.CKM},${this.EnterpriseMC},${this.station_name},${this.station_Type},${this.KSSJ},${this.JSSJ}`;
      // console.log(ID);

      let HTTPURL = this.$store.state.HTTPURL;
      // let HTTPURL = "http://www.warpsoft.cn:7186";

      // console.log(HTTPURL);

      window.open(
        "" +
          HTTPURL +
          "/data/RPT_GetPrintExcelFile.aspx?Module=SP&UID=admin&PID=&Template=104C4B0D23F6410B91A2984DHJJCTYLB&ID=" +
          ID +
          ""
      );
    },

    //分页
    onChange(page, pageSize) {
      console.log(page);
      let me = this;
      this.current = page;
      me.HJJC_Enterprise_Aggregate_Query_Ext();
    },

    //选择基站类型
    handleChange(val) {
      console.log(val);
      let me = this;
      me.station_Type = val.FieldBH;
      me.HJJC_Enterprise_Aggregate_Query_Ext();
    },

    //按钮-确定
    onSubmit() {
      this.current = 1;
      this.HJJC_Enterprise_Aggregate_Query_Ext();
    },

    HJJC_Enterprise_Aggregate_Query_Ext() {
      var me = this;

      var params = {
        CKM: this.$store.state.loggedIn.CKM,
        EnterpriseMC: me.EnterpriseMC,
        station_name: me.station_name,
        station_Type: me.station_Type,
        KSSJ: me.KSSJ,
        JSSJ: me.JSSJ,
        PAGESIZE: this.pageSize,
        PAGENUMBER: this.current,
      };

      this.loading = true;

      http({
        url: "/data/q30/HJJC_Enterprise_Aggregate_Query_Ext.aspx",
        data: params,
        success(obj) {
          console.log(obj);

          if (me.station_Type) {
            let columns = [
              {
                title: "序号",
                align: "center",
                width: 60,
                customRender: (text, record, index) => {
                  return index + 1;
                },
              },
              {
                title: "企业名称",
                dataIndex: "EnterpriseMC",
                align: "center",
                width: 260,
                ellipsis: true,
              },
              {
                title: "基站名称",
                dataIndex: "station_name",
                  width: 160,
                align: "center",
              },
              {
                title: "基站类型",
                dataIndex: "station_TypeName",
                align: "center",
                  width: 160,
                render: (text, record, index) => {
                  return "基站类型";
                },
              },
              {
                title: "综合排放量(kg)",
                dataIndex: "dischargeTotal",
                align: "center",
                  width: 160,
              },
              {
                title: "税额合计(元)",
                dataIndex: "TaxAmountTotal",
                align: "center",
                  width: 160,
              },
            ];

            if (obj.Data2 && obj.Data2.length > 0) {
              for (var i = 0; i < obj.Data2.length; i++) {
                columns.push({
                  title: obj.Data2[i].ItemName,
                  dataIndex: obj.Data2[i].ItemCode + "_dischargeTotal",
                  align: "center",
                  width: 120,
                });
              }

              me.columns = columns;
              me.scroll = {x: 800, y: 530 };
            }
          }

          me.loading = false;

          me.data = obj.Data;

          if (obj.Data1 && obj.Data1.length > 0) {
            me.total = obj.Data1[0].ROWSCOUNT;
          }
        },
        failure(obj) {
          me.loading = false;
        },
        me,
      });
    },
  },
  destoryed() {
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
#alarm {
  color: #181818;
  width: 1200px;
  height: 100%;
  padding-top: 20px;
  margin: 0 auto;

  .content {
    background-color: #ffffff;
    padding: 20px 20px 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    .title {
      text-align: center;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 25px;
      margin-bottom: 24px;
    }

    .search-criteria {
      .form-some {
        display: flex;
        align-items: center;
        margin-right: 20px;

        .large-title {
          margin-right: 10px;
        }
      }
    }

    .table {
      flex: 1;
      padding-top: 24px;
      display: flex;
      flex-direction: column;

      .ant-table-wrapper {
        flex: 1;
      }

      .utilization-text {
        display: flex;
        align-items: center;

        .utilization-shell {
          width: 128px;
          height: 16px;
          background: rgba(24, 118, 255, 0.15);
          border-radius: 3px;
          margin-right: 10px;

          .utilization {
            height: 100%;
            background-color: #4993ff;
          }
        }
      }

      @import "../../assets/css/AppHome.scss";
    }
  }

  &::v-deep .ant-btn-primary {
    color: #ffffff;
    background-color: #1876ff;
  }
}
</style>
